export default {
	intro: {
        name: <>Start</>,
		subtitle: <>Ästhetische Botulinumtoxin- und Hyaluronbehandlung</>,
		title: <>Dein Schönstes Ich.</>,
	},
	about: {
        name: <>Über mich</>,
		subtitle: <>Über mich</>,
		title: <>Mein Name ist Regina Berndt.</>,
		paragraphs: [
			<>Von Hause aus bin ich Kinderärztin in Cottbus. Anfang 2022 habe ich an der Kiel Medical Academy eine Weiterbildung für die ästhetische Behandlung mit Hyaluronfillern und Botulinumtoxin erfolgreich abgeschlossen. Nach vielen positiven Rückmeldungen habe ich mich entschlossen, die Behandlungen jetzt auch in meiner Praxis in der <a href="https://www.reha-vita.de/">Reha Vita Cottbus</a> anzubieten.</>,
			<>Ich wohne mit meinem Mann und zwei Söhnen im Herzen von Cottbus. In meiner Freizeit mache ich gerne Yoga und ausgiebige Spaziergänge.</>,
		],
	},
	service: {
        name: <>Behandlungen</>,
		subtitle: <>Behandlungen</>,
		botox: {
			title: <>Botulinumtoxin.</>,
			paragraphs: [
				<>Mit zunehmendem Alter zeigen sich Veränderungen im Gesicht. Manche mag man, andere weniger. <b>Zornesfalten</b> geben der Mimik einen Ausruck von Griesgrämigkeit, <b>waagerechte Falten</b> furchen die Stirn oder freundliche Lachfältchen verwandeln sich in <b>Krähenfüße</b>. Diese <b>drei Faltenregionen</b> lassen sich mit minimalen Dosen Botulinumtoxins glätten.</>,
				<>Die Behandlung ist vollkommen ungefährlich und dauert etwa <b>20 Minuten</b>. Danach sind Sie sofort wieder gesellschaftsfähig. In manchen Fällen treten geringfügige blaue Flecken auf, diese lassen sich einfach überschminken und verschwinden nach wenigen Tagen. Der positive Effekt hält etwa <b>3 Monate</b> an. Ich verwende ausschließlich nach deutschem Arzneimittelgesetz zugelassenes Botulinumtoxin höchster Qualität.</>,
				<>Die Preise für eine Behandlung beginnen <b>ab etwa 265€</b>, je nach Anzahl behandelter Faltenregionen. Die Abrechnung erfolgt nach der Gebührenordnung für Ärzte (GOÄ).</>,
			],
			next: <>{"Hyaluronfiller >"}</>
		},
		hyaluron: {
			title: <>Hyaluronfiller.</>,
			paragraphs: [
				<>Im mittleren Gesichtsdrittel geht im Laufe der Jahre Volumen verloren oder verschiebt sich nach unten. Das kann zu <b>hohlen Wangen</b> führen. Zudem können tiefe Falten zwischen Nase und Mundwinkel (<b>Nasolabialfalte</b>) oder Mundwinkel und Kinn (<b>Marionettenfalte</b>) entstehen. Mit Hyaluronfillern lässt sich das Volumen wiederherstellen.</>,
				<>Die etwa <b>20 Minuten</b> lange Behandlung kann wahlweise durch ein lokales Anästhetikum noch schmerzärmer gestaltet werden. Die leichte Betäubung hält für wenige Stunden an, behindert aber nicht beim Essen oder Sprechen. Die positiven Effekte sind für <b>3 bis 9 Monate</b> zu sehen. Ich verwende ausschließlich hochwertigste Hyaluronfiller der deutschen Firma Merz.</>,
				<>Die Preise für eine Behandlung beginnen <b>ab etwa 222€</b>, je nach der Anzahl benötigter Ampullen. Diese hängt von der Tiefe der Falten sowie dem gewünschten Korrekturlevel ab. Die Abrechnung erfolgt nach der Gebührenordnung für Ärzte (GOÄ).</>,
			],
			next: <>{"Botulinumtoxin >"}</>
		},
	},
	contact: {
        name: <>Kontakt</>,
		title: <>Hier finden Sie mich.</>,
		paragraphs: [
			<>Termine nach Absprache auf WhatsApp unter <a href="tel:491607403016">+49 160 740 3016</a></>,
			<>Kinderarztpraxis Musikowski, Rosenberger und Berndt</>,
			<>Ostrower Platz 18, 03046 Cottbus</>,
		],
	},
	impressum: {
		title: <>Impressum</>,
		paragraphs: [

		],
	},
	datenschutz: {
		title: <>Datenschutz</>,
		paragraphs: [

		],
	},
    nav: {
        open: <>Navigation öffnen</>,
        close: <>Navigation schließen</>,
    }
}
